import { RouteInfo } from "./sidebar.metadata";
export const ROUTES: RouteInfo[] = [
  {
    path: "",
    title: "MENUITEMS.MAIN.TEXT",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    submenu: [],
  },
  {
    path: "/dashboard",
    title: "MENUITEMS.DASHBOARD.TEXT",
    iconType: "feather",
    icon: "grid",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    submenu: [],
  },
  {
    path: "",
    title: "Pacientes",
    iconType: "feather",
    icon: "grid",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    submenu: [
      {
        path: "/devices/list",
        title: "Todos",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
    ],
  },
  // {
  //   path: "",
  //   title: "Tareas",
  //   iconType: "feather",
  //   icon: "grid",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   submenu: [
  //     {
  //       path: "/tasks/camp",
  //       title: "Campo",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       submenu: [],
  //     },
  //     {
  //       path: "/tasks/wet",
  //       title: "En Húmedo",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       submenu: [],
  //     },
  //     {
  //       path: "/tasks/dry",
  //       title: "En Seco",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Configuración",
  //   iconType: "feather",
  //   icon: "grid",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   submenu: [
  //     {
  //       path: "/devices/list",
  //       title: "Dispositivos",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "MENUITEMS.CATALOGS.TEXT",
  //   iconType: "feather",
  //   icon: "grid",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   submenu: [
  //     {
  //       path: "/list/farms",
  //       title: "MENUITEMS.CATALOGS.LIST.FINCA",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       submenu: [],
  //     },
  //     {
  //       path: "/list/blocks",
  //       title: "MENUITEMS.CATALOGS.LIST.BLOQUE",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       submenu: [],
  //     },
  //     {
  //       path: "/list/lots",
  //       title: "MENUITEMS.CATALOGS.LIST.LOTE",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       submenu: [],
  //     },
  //     {
  //       path: "/list/catalog/crops",
  //       title: "MENUITEMS.CATALOGS.LIST.CULTIVO",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       submenu: [],
  //     },
  //     {
  //       path: "/list/catalog/variety",
  //       title: "MENUITEMS.CATALOGS.LIST.VARIEDAD",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       submenu: [],
  //     },
  //     {
  //       path: "/list/catalog/responsible",
  //       title: "MENUITEMS.RESPONSIBLE.TEXT",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       submenu: [],
  //     },
  //   ],
  // },
];
